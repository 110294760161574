import { RNPluginCreator } from "./type";

const emojis = [
  { emoji: "😀", text: "Grinning Face" },
  { emoji: "😃", text: "Grinning Face with Big Eyes" },
  { emoji: "😄", text: "Grinning Face with Smiling Eyes" },
  { emoji: "😁", text: "Beaming Face with Smiling Eyes" },
  { emoji: "😆", text: "Grinning Squinting Face" },
  { emoji: "😅", text: "Grinning Face with Sweat" },
  { emoji: "🤣", text: "Rolling on the Floor Laughing" },
  { emoji: "😂", text: "Face with Tears of Joy" },
  { emoji: "🙂", text: "Slightly Smiling Face" },
  { emoji: "🙃", text: "Upside-Down Face" },
  { emoji: "🫠", text: "Melting Face" },
  { emoji: "😉", text: "Winking Face" },
  { emoji: "😊", text: "Smiling Face with Smiling Eyes" },
  { emoji: "😇", text: "Smiling Face with Halo" },
  { emoji: "🥰", text: "Smiling Face with Hearts" },
  { emoji: "😍", text: "Smiling Face with Heart-Eyes" },
  { emoji: "🤩", text: "Star-Struck" },
  { emoji: "😘", text: "Face Blowing a Kiss" },
  { emoji: "😗", text: "Kissing Face" },
  { emoji: "☺️", text: "Smiling Face" },
  { emoji: "😚", text: "Kissing Face with Closed Eyes" },
  { emoji: "😙", text: "Kissing Face with Smiling Eyes" },
  { emoji: "🥲", text: "Smiling Face with Tear" },
  { emoji: "😋", text: "Face Savoring Food" },
  { emoji: "😛", text: "Face with Tongue" },
  { emoji: "😜", text: "Winking Face with Tongue" },
  { emoji: "🤪", text: "Zany Face" },
  { emoji: "😝", text: "Squinting Face with Tongue" },
  { emoji: "🤑", text: "Money-Mouth Face" },
  { emoji: "🤗", text: "Smiling Face with Open Hands" },
  { emoji: "🤭", text: "Face with Hand Over Mouth" },
  { emoji: "🫢", text: "Face with Open Eyes and Hand Over Mouth" },
  { emoji: "🫣", text: "Face with Peeking Eye" },
  { emoji: "🤫", text: "Shushing Face" },
  { emoji: "🤔", text: "Thinking Face" },
  { emoji: "🫡", text: "Saluting Face" },
  { emoji: "🤐", text: "Zipper-Mouth Face" },
  { emoji: "🤨", text: "Face with Raised Eyebrow" },
  { emoji: "😐", text: "Neutral Face" },
  { emoji: "😑", text: "Expressionless Face" },
  { emoji: "😶", text: "Face Without Mouth" },
  { emoji: "🫥", text: "Dotted Line Face" },
  { emoji: "😶‍🌫️", text: "Face in Clouds" },
  { emoji: "😏", text: "Smirking Face" },
  { emoji: "😒", text: "Unamused Face" },
  { emoji: "🙄", text: "Face with Rolling Eyes" },
  { emoji: "😬", text: "Grimacing Face" },
  { emoji: "🫨", text: "Shaking Face" },
  { emoji: "😮‍💨", text: "Face Exhaling" },
  { emoji: "🤥", text: "Lying Face" },
  { emoji: "😌", text: "Relieved Face" },
  { emoji: "😔", text: "Pensive Face" },
  { emoji: "😪", text: "Sleepy Face" },
  { emoji: "🤤", text: "Drooling Face" },
  { emoji: "😴", text: "Sleeping Face" },
  { emoji: "😷", text: "Face with Medical Mask" },
  { emoji: "🤒", text: "Face with Thermometer" },
  { emoji: "🤕", text: "Face with Head-Bandage" },
  { emoji: "🤢", text: "Nauseated Face" },
  { emoji: "🤮", text: "Face Vomiting" },
  { emoji: "🤧", text: "Sneezing Face" },
  { emoji: "🥵", text: "Hot Face" },
  { emoji: "🥶", text: "Cold Face" },
  { emoji: "🥴", text: "Woozy Face" },
  { emoji: "😵", text: "Face with Crossed-Out Eyes" },
  { emoji: "😵‍💫", text: "Face with Spiral Eyes" },
  { emoji: "🤯", text: "Exploding Head" },
  { emoji: "🤠", text: "Cowboy Hat Face" },
  { emoji: "🥳", text: "Partying Face" },
  { emoji: "🥸", text: "Disguised Face" },
  { emoji: "😎", text: "Smiling Face with Sunglasses" },
  { emoji: "🤓", text: "Nerd Face" },
  { emoji: "🧐", text: "Face with Monocle" },
  { emoji: "😕", text: "Confused Face" },
  { emoji: "🫤", text: "Face with Diagonal Mouth" },
  { emoji: "😟", text: "Worried Face" },
  { emoji: "🙁", text: "Slightly Frowning Face" },
  { emoji: "☹️", text: "Frowning Face" },
  { emoji: "😮", text: "Face with Open Mouth" },
  { emoji: "😯", text: "Hushed Face" },
  { emoji: "😲", text: "Astonished Face" },
  { emoji: "😳", text: "Flushed Face" },
  { emoji: "🥺", text: "Pleading Face" },
  { emoji: "🥹", text: "Face Holding Back Tears" },
  { emoji: "😦", text: "Frowning Face with Open Mouth" },
  { emoji: "😧", text: "Anguished Face" },
  { emoji: "😨", text: "Fearful Face" },
  { emoji: "😰", text: "Anxious Face with Sweat" },
  { emoji: "😥", text: "Sad but Relieved Face" },
  { emoji: "😢", text: "Crying Face" },
  { emoji: "😭", text: "Loudly Crying Face" },
  { emoji: "😱", text: "Face Screaming in Fear" },
  { emoji: "😖", text: "Confounded Face" },
  { emoji: "😣", text: "Persevering Face" },
  { emoji: "😞", text: "Disappointed Face" },
  { emoji: "😓", text: "Downcast Face with Sweat" },
  { emoji: "😩", text: "Weary Face" },
  { emoji: "😫", text: "Tired Face" },
  { emoji: "🥱", text: "Yawning Face" },
  { emoji: "😤", text: "Face with Steam From Nose" },
  { emoji: "😡", text: "Enraged Face" },
  { emoji: "😠", text: "Angry Face" },
  { emoji: "🤬", text: "Face with Symbols on Mouth" },
  { emoji: "😈", text: "Smiling Face with Horns" },
  { emoji: "👿", text: "Angry Face with Horns" },
  { emoji: "💀", text: "Skull" },
  { emoji: "☠️", text: "Skull and Crossbones" },
  { emoji: "💩", text: "Pile of Poo" },
  { emoji: "🤡", text: "Clown Face" },
  { emoji: "👹", text: "Ogre" },
  { emoji: "👺", text: "Goblin" },
  { emoji: "👻", text: "Ghost" },
  { emoji: "👽", text: "Alien" },
  { emoji: "👾", text: "Alien Monster" },
  { emoji: "🤖", text: "Robot" },
  { emoji: "😺", text: "Grinning Cat" },
  { emoji: "😸", text: "Grinning Cat with Smiling Eyes" },
  { emoji: "😹", text: "Cat with Tears of Joy" },
  { emoji: "😻", text: "Smiling Cat with Heart-Eyes" },
  { emoji: "😼", text: "Cat with Wry Smile" },
  { emoji: "😽", text: "Kissing Cat" },
  { emoji: "🙀", text: "Weary Cat" },
  { emoji: "😿", text: "Crying Cat" },
  { emoji: "😾", text: "Pouting Cat" },
  { emoji: "💋", text: "Kiss Mark" },
  { emoji: "👋", text: "Waving Hand" },
  { emoji: "🤚", text: "Raised Back of Hand" },
  { emoji: "🖐️", text: "Hand with Fingers Splayed" },
  { emoji: "✋", text: "Raised Hand" },
  { emoji: "🖖", text: "Vulcan Salute" },
  { emoji: "🫱", text: "Rightwards Hand" },
  { emoji: "🫲", text: "Leftwards Hand" },
  { emoji: "🫳", text: "Palm Down Hand" },
  { emoji: "🫴", text: "Palm Up Hand" },
  { emoji: "👌", text: "OK Hand" },
  { emoji: "🤌", text: "Pinched Fingers" },
  { emoji: "🤏", text: "Pinching Hand" },
  { emoji: "✌️", text: "Victory Hand" },
  { emoji: "🤞", text: "Crossed Fingers" },
  { emoji: "🫰", text: "Hand with Index Finger and Thumb Crossed" },
  { emoji: "🤟", text: "Love-You Gesture" },
  { emoji: "🤘", text: "Sign of the Horns" },
  { emoji: "🤙", text: "Call Me Hand" },
  { emoji: "👈", text: "Backhand Index Pointing Left" },
  { emoji: "👉", text: "Backhand Index Pointing Right" },
  { emoji: "👆", text: "Backhand Index Pointing Up" },
  { emoji: "🖕", text: "Middle Finger" },
  { emoji: "👇", text: "Backhand Index Pointing Down" },
  { emoji: "☝️", text: "Index Pointing Up" },
  { emoji: "🫵", text: "Index Pointing at the Viewer" },
  { emoji: "👍", text: "Thumbs Up" },
  { emoji: "👎", text: "Thumbs Down" },
  { emoji: "✊", text: "Raised Fist" },
  { emoji: "👊", text: "Oncoming Fist" },
  { emoji: "🤛", text: "Left-Facing Fist" },
  { emoji: "🤜", text: "Right-Facing Fist" },
  { emoji: "👏", text: "Clapping Hands" },
  { emoji: "🙌", text: "Raising Hands" },
  { emoji: "🫶", text: "Heart Hands" },
  { emoji: "👐", text: "Open Hands" },
  { emoji: "🤲", text: "Palms Up Together" },
  { emoji: "🤝", text: "Handshake" },
  { emoji: "🙏", text: "Folded Hands" },
  { emoji: "✍️", text: "Writing Hand" },
  { emoji: "💅", text: "Nail Polish" },
  { emoji: "🤳", text: "Selfie" },
  { emoji: "💪", text: "Flexed Biceps" },
  { emoji: "🦾", text: "Mechanical Arm" },
  { emoji: "🦿", text: "Mechanical Leg" },
  { emoji: "🦵", text: "Leg" },
  { emoji: "🦶", text: "Foot" },
  { emoji: "👂", text: "Ear" },
  { emoji: "🦻", text: "Ear with Hearing Aid" },
  { emoji: "👃", text: "Nose" },
  { emoji: "🧠", text: "Brain" },
  { emoji: "🫀", text: "Anatomical Heart" },
  { emoji: "🫁", text: "Lungs" },
  { emoji: "🦷", text: "Tooth" },
  { emoji: "🦴", text: "Bone" },
  { emoji: "👀", text: "Eyes" },
  { emoji: "👁️", text: "Eye" },
  { emoji: "👅", text: "Tongue" },
  { emoji: "👄", text: "Mouth" },
  { emoji: "🫦", text: "Biting Lip" },
  { emoji: "👶", text: "Baby" },
  { emoji: "🧒", text: "Child" },
  { emoji: "👦", text: "Boy" },
  { emoji: "👧", text: "Girl" },
  { emoji: "🧑", text: "Person" },
  { emoji: "👱", text: "Person: Blond Hair" },
  { emoji: "👨", text: "Man" },
  { emoji: "🧔", text: "Person: Beard" },
  { emoji: "👨‍🦰", text: "Man: Red Hair" },
  { emoji: "👨‍🦱", text: "Man: Curly Hair" },
  { emoji: "👨‍🦳", text: "Man: White Hair" },
  { emoji: "👨‍🦲", text: "Man: Bald" },
  { emoji: "👩", text: "Woman" },
  { emoji: "👩‍🦰", text: "Woman: Red Hair" },
  { emoji: "🧑‍🦰", text: "Person: Red Hair" },
  { emoji: "👩‍🦱", text: "Woman: Curly Hair" },
  { emoji: "🧑‍🦱", text: "Person: Curly Hair" },
  { emoji: "👩‍🦳", text: "Woman: White Hair" },
  { emoji: "🧑‍🦳", text: "Person: White Hair" },
  { emoji: "👩‍🦲", text: "Woman: Bald" },
  { emoji: "🧑‍🦲", text: "Person: Bald" },
  { emoji: "👱‍♀️", text: "Woman: Blond Hair" },
  { emoji: "👱‍♂️", text: "Man: Blond Hair" },
  { emoji: "🧓", text: "Older Person" },
  { emoji: "👴", text: "Old Man" },
  { emoji: "👵", text: "Old Woman" },
  { emoji: "🙍", text: "Person Frowning" },
  { emoji: "🙍‍♂️", text: "Man Frowning" },
  { emoji: "🙍‍♀️", text: "Woman Frowning" },
  { emoji: "🙎", text: "Person Pouting" },
  { emoji: "🙎‍♂️", text: "Man Pouting" },
  { emoji: "🙎‍♀️", text: "Woman Pouting" },
  { emoji: "🙅", text: "Person Gesturing No" },
  { emoji: "🙅‍♂️", text: "Man Gesturing No" },
  { emoji: "🙅‍♀️", text: "Woman Gesturing No" },
  { emoji: "🙆", text: "Person Gesturing OK" },
  { emoji: "🙆‍♂️", text: "Man Gesturing OK" },
  { emoji: "🙆‍♀️", text: "Woman Gesturing OK" },
  { emoji: "💁", text: "Person Tipping Hand" },
  { emoji: "💁‍♂️", text: "Man Tipping Hand" },
  { emoji: "💁‍♀️", text: "Woman Tipping Hand" },
  { emoji: "🙋", text: "Person Raising Hand" },
  { emoji: "🙋‍♂️", text: "Man Raising Hand" },
  { emoji: "🙋‍♀️", text: "Woman Raising Hand" },
  { emoji: "🧏", text: "Deaf Person" },
  { emoji: "🧏‍♂️", text: "Deaf Man" },
  { emoji: "🧏‍♀️", text: "Deaf Woman" },
  { emoji: "🙇", text: "Person Bowing" },
  { emoji: "🙇‍♂️", text: "Man Bowing" },
  { emoji: "🙇‍♀️", text: "Woman Bowing" },
  { emoji: "🤦", text: "Person Facepalming" },
  { emoji: "🤦‍♂️", text: "Man Facepalming" },
  { emoji: "🤦‍♀️", text: "Woman Facepalming" },
  { emoji: "🤷", text: "Person Shrugging" },
  { emoji: "🤷‍♂️", text: "Man Shrugging" },
  { emoji: "🤷‍♀️", text: "Woman Shrugging" },
  { emoji: "🧑‍⚕️", text: "Health Worker" },
  { emoji: "👨‍⚕️", text: "Man Health Worker" },
  { emoji: "👩‍⚕️", text: "Woman Health Worker" },
  { emoji: "🧑‍🎓", text: "Student" },
  { emoji: "👨‍🎓", text: "Man Student" },
  { emoji: "👩‍🎓", text: "Woman Student" },
  { emoji: "🧑‍🏫", text: "Teacher" },
  { emoji: "👨‍🏫", text: "Man Teacher" },
  { emoji: "👩‍🏫", text: "Woman Teacher" },
  { emoji: "🧑‍⚖️", text: "Judge" },
  { emoji: "👨‍⚖️", text: "Man Judge" },
  { emoji: "👩‍⚖️", text: "Woman Judge" },
  { emoji: "🧑‍🌾", text: "Farmer" },
  { emoji: "👨‍🌾", text: "Man Farmer" },
  { emoji: "👩‍🌾", text: "Woman Farmer" },
  { emoji: "🧑‍🍳", text: "Cook" },
  { emoji: "👨‍🍳", text: "Man Cook" },
  { emoji: "👩‍🍳", text: "Woman Cook" },
  { emoji: "🧑‍🔧", text: "Mechanic" },
  { emoji: "👨‍🔧", text: "Man Mechanic" },
  { emoji: "👩‍🔧", text: "Woman Mechanic" },
  { emoji: "🧑‍🏭", text: "Factory Worker" },
  { emoji: "👨‍🏭", text: "Man Factory Worker" },
  { emoji: "👩‍🏭", text: "Woman Factory Worker" },
  { emoji: "🧑‍💼", text: "Office Worker" },
  { emoji: "👨‍💼", text: "Man Office Worker" },
  { emoji: "👩‍💼", text: "Woman Office Worker" },
  { emoji: "🧑‍🔬", text: "Scientist" },
  { emoji: "👨‍🔬", text: "Man Scientist" },
  { emoji: "👩‍🔬", text: "Woman Scientist" },
  { emoji: "🧑‍💻", text: "Technologist" },
  { emoji: "👨‍💻", text: "Man Technologist" },
  { emoji: "👩‍💻", text: "Woman Technologist" },
  { emoji: "🧑‍🎤", text: "Singer" },
  { emoji: "👨‍🎤", text: "Man Singer" },
  { emoji: "👩‍🎤", text: "Woman Singer" },
  { emoji: "🧑‍🎨", text: "Artist" },
  { emoji: "👨‍🎨", text: "Man Artist" },
  { emoji: "👩‍🎨", text: "Woman Artist" },
  { emoji: "🧑‍✈️", text: "Pilot" },
  { emoji: "👨‍✈️", text: "Man Pilot" },
  { emoji: "👩‍✈️", text: "Woman Pilot" },
  { emoji: "🧑‍🚀", text: "Astronaut" },
  { emoji: "👨‍🚀", text: "Man Astronaut" },
  { emoji: "👩‍🚀", text: "Woman Astronaut" },
  { emoji: "🧑‍🚒", text: "Firefighter" },
  { emoji: "👨‍🚒", text: "Man Firefighter" },
  { emoji: "👩‍🚒", text: "Woman Firefighter" },
  { emoji: "👮", text: "Police Officer" },
  { emoji: "👮‍♂️", text: "Man Police Officer" },
  { emoji: "👮‍♀️", text: "Woman Police Officer" },
  { emoji: "🕵️", text: "Detective" },
  { emoji: "🕵️‍♂️", text: "Man Detective" },
  { emoji: "🕵️‍♀️", text: "Woman Detective" },
  { emoji: "💂", text: "Guard" },
  { emoji: "💂‍♂️", text: "Man Guard" },
  { emoji: "💂‍♀️", text: "Woman Guard" },
  { emoji: "🥷", text: "Ninja" },
  { emoji: "👷", text: "Construction Worker" },
  { emoji: "👷‍♂️", text: "Man Construction Worker" },
  { emoji: "👷‍♀️", text: "Woman Construction Worker" },
  { emoji: "🫅", text: "Person with Crown" },
  { emoji: "🤴", text: "Prince" },
  { emoji: "👸", text: "Princess" },
  { emoji: "👳", text: "Person Wearing Turban" },
  { emoji: "👳‍♂️", text: "Man Wearing Turban" },
  { emoji: "👳‍♀️", text: "Woman Wearing Turban" },
  { emoji: "👲", text: "Person with Skullcap" },
  { emoji: "🧕", text: "Woman with Headscarf" },
  { emoji: "🤵", text: "Person in Tuxedo" },
  { emoji: "🤵‍♂️", text: "Man in Tuxedo" },
  { emoji: "🤵‍♀️", text: "Woman in Tuxedo" },
  { emoji: "👰", text: "Person with Veil" },
  { emoji: "👰‍♂️", text: "Man with Veil" },
  { emoji: "👰‍♀️", text: "Woman with Veil" },
  { emoji: "🤰", text: "Pregnant Woman" },
  { emoji: "🫃", text: "Pregnant Man" },
  { emoji: "🫄", text: "Pregnant Person" },
  { emoji: "🤱", text: "Breast-Feeding" },
  { emoji: "👩‍🍼", text: "Woman Feeding Baby" },
  { emoji: "👨‍🍼", text: "Man Feeding Baby" },
  { emoji: "🧑‍🍼", text: "Person Feeding Baby" },
  { emoji: "👼", text: "Baby Angel" },
  { emoji: "🎅", text: "Santa Claus" },
  { emoji: "🤶", text: "Mrs. Claus" },
  { emoji: "🧑‍🎄", text: "Mx Claus" },
  { emoji: "🦸", text: "Superhero" },
  { emoji: "🦸‍♂️", text: "Man Superhero" },
  { emoji: "🦸‍♀️", text: "Woman Superhero" },
  { emoji: "🦹", text: "Supervillain" },
  { emoji: "🦹‍♂️", text: "Man Supervillain" },
  { emoji: "🦹‍♀️", text: "Woman Supervillain" },
  { emoji: "🧙", text: "Mage" },
  { emoji: "🧙‍♂️", text: "Man Mage" },
  { emoji: "🧙‍♀️", text: "Woman Mage" },
  { emoji: "🧚", text: "Fairy" },
  { emoji: "🧚‍♂️", text: "Man Fairy" },
  { emoji: "🧚‍♀️", text: "Woman Fairy" },
  { emoji: "🧛", text: "Vampire" },
  { emoji: "🧛‍♂️", text: "Man Vampire" },
  { emoji: "🧛‍♀️", text: "Woman Vampire" },
  { emoji: "🧜", text: "Merperson" },
  { emoji: "🧜‍♂️", text: "Merman" },
  { emoji: "🧜‍♀️", text: "Mermaid" },
  { emoji: "🧝", text: "Elf" },
  { emoji: "🧝‍♂️", text: "Man Elf" },
  { emoji: "🧝‍♀️", text: "Woman Elf" },
  { emoji: "🧞", text: "Genie" },
  { emoji: "🧞‍♂️", text: "Man Genie" },
  { emoji: "🧞‍♀️", text: "Woman Genie" },
  { emoji: "🧟", text: "Zombie" },
  { emoji: "🧟‍♂️", text: "Man Zombie" },
  { emoji: "🧟‍♀️", text: "Woman Zombie" },
  { emoji: "🧌", text: "Troll" },
  { emoji: "💆", text: "Person Getting Massage" },
  { emoji: "💆‍♂️", text: "Man Getting Massage" },
  { emoji: "💆‍♀️", text: "Woman Getting Massage" },
  { emoji: "💇", text: "Person Getting Haircut" },
  { emoji: "💇‍♂️", text: "Man Getting Haircut" },
  { emoji: "💇‍♀️", text: "Woman Getting Haircut" },
  { emoji: "🚶", text: "Person Walking" },
  { emoji: "🚶‍♂️", text: "Man Walking" },
  { emoji: "🚶‍♀️", text: "Woman Walking" },
  { emoji: "🧍", text: "Person Standing" },
  { emoji: "🧍‍♂️", text: "Man Standing" },
  { emoji: "🧍‍♀️", text: "Woman Standing" },
  { emoji: "🧎", text: "Person Kneeling" },
  { emoji: "🧎‍♂️", text: "Man Kneeling" },
  { emoji: "🧎‍♀️", text: "Woman Kneeling" },
  { emoji: "🧑‍🦯", text: "Person with White Cane" },
  { emoji: "👨‍🦯", text: "Man with White Cane" },
  { emoji: "👩‍🦯", text: "Woman with White Cane" },
  { emoji: "🧑‍🦼", text: "Person in Motorized Wheelchair" },
  { emoji: "👨‍🦼", text: "Man in Motorized Wheelchair" },
  { emoji: "👩‍🦼", text: "Woman in Motorized Wheelchair" },
  { emoji: "🧑‍🦽", text: "Person in Manual Wheelchair" },
  { emoji: "👨‍🦽", text: "Man in Manual Wheelchair" },
  { emoji: "👩‍🦽", text: "Woman in Manual Wheelchair" },
  { emoji: "🏃", text: "Person Running" },
  { emoji: "🏃‍♂️", text: "Man Running" },
  { emoji: "🏃‍♀️", text: "Woman Running" },
  { emoji: "💃", text: "Woman Dancing" },
  { emoji: "🕺", text: "Man Dancing" },
  { emoji: "🕴️", text: "Person in Suit Levitating" },
  { emoji: "👯", text: "People with Bunny Ears" },
  { emoji: "👯‍♂️", text: "Men with Bunny Ears" },
  { emoji: "👯‍♀️", text: "Women with Bunny Ears" },
  { emoji: "🧖", text: "Person in Steamy Room" },
  { emoji: "🧖‍♂️", text: "Man in Steamy Room" },
  { emoji: "🧖‍♀️", text: "Woman in Steamy Room" },
  { emoji: "🧘", text: "Person in Lotus Position" },
  { emoji: "🧑‍🤝‍🧑", text: "People Holding Hands" },
  { emoji: "👭", text: "Women Holding Hands" },
  { emoji: "👫", text: "Woman and Man Holding Hands" },
  { emoji: "👬", text: "Men Holding Hands" },
  { emoji: "💏", text: "Kiss" },
  { emoji: "👩‍❤️‍💋‍👨", text: "Kiss: Woman, Man" },
  { emoji: "👨‍❤️‍💋‍👨", text: "Kiss: Man, Man" },
  { emoji: "👩‍❤️‍💋‍👩", text: "Kiss: Woman, Woman" },
  { emoji: "💑", text: "Couple with Heart" },
  { emoji: "👩‍❤️‍👨", text: "Couple with Heart: Woman, Man" },
  { emoji: "👨‍❤️‍👨", text: "Couple with Heart: Man, Man" },
  { emoji: "👩‍❤️‍👩", text: "Couple with Heart: Woman, Woman" },
  { emoji: "👪", text: "Family" },
  { emoji: "👨‍👩‍👦", text: "Family: Man, Woman, Boy" },
  { emoji: "👨‍👩‍👧", text: "Family: Man, Woman, Girl" },
  { emoji: "👨‍👩‍👧‍👦", text: "Family: Man, Woman, Girl, Boy" },
  { emoji: "👨‍👩‍👦‍👦", text: "Family: Man, Woman, Boy, Boy" },
  { emoji: "👨‍👩‍👧‍👧", text: "Family: Man, Woman, Girl, Girl" },
  { emoji: "👨‍👨‍👦", text: "Family: Man, Man, Boy" },
  { emoji: "👨‍👨‍👧", text: "Family: Man, Man, Girl" },
  { emoji: "👨‍👨‍👧‍👦", text: "Family: Man, Man, Girl, Boy" },
  { emoji: "👨‍👨‍👦‍👦", text: "Family: Man, Man, Boy, Boy" },
  { emoji: "👨‍👨‍👧‍👧", text: "Family: Man, Man, Girl, Girl" },
  { emoji: "👩‍👩‍👦", text: "Family: Woman, Woman, Boy" },
  { emoji: "👩‍👩‍👧", text: "Family: Woman, Woman, Girl" },
  { emoji: "👩‍👩‍👧‍👦", text: "Family: Woman, Woman, Girl, Boy" },
  { emoji: "👩‍👩‍👦‍👦", text: "Family: Woman, Woman, Boy, Boy" },
  { emoji: "👩‍👩‍👧‍👧", text: "Family: Woman, Woman, Girl, Girl" },
  { emoji: "👨‍👦", text: "Family: Man, Boy" },
  { emoji: "👨‍👦‍👦", text: "Family: Man, Boy, Boy" },
  { emoji: "👨‍👧", text: "Family: Man, Girl" },
  { emoji: "👨‍👧‍👦", text: "Family: Man, Girl, Boy" },
  { emoji: "👨‍👧‍👧", text: "Family: Man, Girl, Girl" },
  { emoji: "👩‍👦", text: "Family: Woman, Boy" },
  { emoji: "👩‍👦‍👦", text: "Family: Woman, Boy, Boy" },
  { emoji: "👩‍👧", text: "Family: Woman, Girl" },
  { emoji: "👩‍👧‍👦", text: "Family: Woman, Girl, Boy" },
  { emoji: "👩‍👧‍👧", text: "Family: Woman, Girl, Girl" },
  { emoji: "🗣️", text: "Speaking Head" },
  { emoji: "👤", text: "Bust in Silhouette" },
  { emoji: "👥", text: "Busts in Silhouette" },
  { emoji: "🫂", text: "People Hugging" },
  { emoji: "👣", text: "Footprints" },
  { emoji: "🧳", text: "Luggage" },
  { emoji: "🌂", text: "Closed Umbrella" },
  { emoji: "☂️", text: "Umbrella" },
  { emoji: "🎃", text: "Jack-O-Lantern" },
  { emoji: "🧵", text: "Thread" },
  { emoji: "🧶", text: "Yarn" },
  { emoji: "👓", text: "Glasses" },
  { emoji: "🕶️", text: "Sunglasses" },
  { emoji: "🥽", text: "Goggles" },
  { emoji: "🥼", text: "Lab Coat" },
  { emoji: "🦺", text: "Safety Vest" },
  { emoji: "👔", text: "Necktie" },
  { emoji: "👕", text: "T-Shirt" },
  { emoji: "👖", text: "Jeans" },
  { emoji: "🧣", text: "Scarf" },
  { emoji: "🧤", text: "Gloves" },
  { emoji: "🧥", text: "Coat" },
  { emoji: "🧦", text: "Socks" },
  { emoji: "👗", text: "Dress" },
  { emoji: "👘", text: "Kimono" },
  { emoji: "🥻", text: "Sari" },
  { emoji: "🩱", text: "One-Piece Swimsuit" },
  { emoji: "🩲", text: "Briefs" },
  { emoji: "🩳", text: "Shorts" },
  { emoji: "👙", text: "Bikini" },
  { emoji: "👚", text: "Woman’s Clothes" },
  { emoji: "👛", text: "Purse" },
  { emoji: "👜", text: "Handbag" },
  { emoji: "👝", text: "Clutch Bag" },
  { emoji: "🎒", text: "Backpack" },
  { emoji: "🩴", text: "Thong Sandal" },
  { emoji: "👞", text: "Man’s Shoe" },
  { emoji: "👟", text: "Running Shoe" },
  { emoji: "🥾", text: "Hiking Boot" },
  { emoji: "🥿", text: "Flat Shoe" },
  { emoji: "👠", text: "High-Heeled Shoe" },
  { emoji: "👡", text: "Woman’s Sandal" },
  { emoji: "🩰", text: "Ballet Shoes" },
  { emoji: "👢", text: "Woman’s Boot" },
  { emoji: "👑", text: "Crown" },
  { emoji: "👒", text: "Woman’s Hat" },
  { emoji: "🎩", text: "Top Hat" },
  { emoji: "🎓", text: "Graduation Cap" },
  { emoji: "🧢", text: "Billed Cap" },
  { emoji: "🪖", text: "Military Helmet" },
  { emoji: "⛑️", text: "Rescue Worker’s Helmet" },
  { emoji: "💄", text: "Lipstick" },
  { emoji: "💍", text: "Ring" },
  { emoji: "💼", text: "Briefcase" },
  { emoji: "🩸", text: "Drop of Blood" },
];

const EmojisPlugin: RNPluginCreator = () => {
  return {
    name: "Emojis",
    version: 1,
    suggestions: {
      "[:": {
        suggest: (prefix, word) => {
          return emojis
            .filter((item) =>
              item.text.toLowerCase().includes(word.toLowerCase())
            )
            .map((item) => ({
              title: item.emoji,
              replace: item.emoji,
              description: item.text,
            }));
        },
      },
    },
  };
};

export default EmojisPlugin;
