import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      x="0"
      y="0"
      enableBackground="new 0 0 662.33 768"
      version="1.1"
      viewBox="0 0 662.33 768"
      xmlSpace="preserve"
      width={"100%"}
    >
      <style></style>
      <path d="M3391.16 0H4591.16V768H3391.16z" className="st0"></path>
      <path
        d="M85.14 446.28C68.88 310.59 166.02 187.02 301.7 170.76c26.84-3.22 53.25-1.99 78.43 3.12 5.54-5.14 10.68-9.99 15.49-14.53-30.52-7.7-62.93-10.02-96.01-6.04C154.3 170.7 50.27 303.1 67.65 448.37c9.69 80.78 54.94 148.79 118.14 190.26 2.06-5.61 4.18-11.25 6.34-16.89-57.3-38.91-98.14-101.41-106.99-175.46zM594.67 385.2c-7.33-61.04-34.97-114.79-75.27-155.32-.17 8.03-.63 16.16-1.33 24.35 31.62 36.19 52.98 81.88 59.12 133.06 16.29 135.68-80.85 259.26-216.53 275.52-31.25 3.75-61.8 1.46-90.57-5.87-5.74 3.48-11.71 8.09-17.58 13.27 34.6 10.78 71.99 14.63 110.24 10.05 145.27-17.42 249.33-149.72 231.92-295.06z"
        className="st0"
      ></path>
      <path
        d="M248.23 643.73c-23.99-47.87 37.42-117.43 37.42-117.43h.03c0-.01.01-.01.01-.02 115.52-19.21 162.87-131.97 163.19-132.75.01-.01.01-.02.02-.03h-.01v-.01l-.06.02-72.92 8.72 84.33-28c28.76-52.25 39.31-98.7 39.31-98.7l-13.57 1.69-37.12 4.71 40.47-16.39 12.18-4.91c1.39-8.89 2.45-17.52 3.18-25.81 2.95-32.58 1.29-60.51-2.32-83.27-7.23-46.11-22.23-71.23-22.23-71.23s0 .03-.03.03c0 .03 0 .07-.03.1v.03c0 .07-.03.1-.07.17v.03c-5.21 13.8-5.44 22.39-38.45 56.16-.03.1-.1.13-.17.2-12.04 12.31-28.4 27.97-50.92 48.37-82.47 74.9-117.13 133.95-119.22 137.53-.07.13-.1.2-.1.2-.03.03-.07.03-.07.03v.07l12.31 72.28-22.3-57.65-.07-.23c-80.53 104.46-38.45 236.37-38.45 236.37 81.84-194.84 188.07-308.26 194.07-314.66C328.3 370.92 274.85 485.67 250.1 546.54c-.1.27-.23.5-.3.7-.03.1-.07.17-.1.23-18.08 41.5-42.03 100.45-50.92 140.2 0 0 11.35-27.68 49.5-43.77-.02-.07-.05-.1-.05-.17z"
        className="st0"
      ></path>
    </svg>
  );
}

export default Icon;
